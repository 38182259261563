
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

// --

.color-error {
  color: var(--color-error);
}

// --

.text-size-10 {
  font-size: 10px;
}

// --

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// --

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix {
  *zoom: 1; /* Für den IE6 und IE7 */

  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

// --

.m-vt-0 {
  margin-top: 0;
}

.m-vt-1 {
  margin-top: 8px;
}

.m-vt-2 {
  margin-top: 16px;
}

.m-vt-3 {
  margin-top: 32px;
}


.m-vb-0 {
  margin-bottom: 0;
}

.m-vb-1 {
  margin-bottom: 8px;
}

.m-vb-2 {
  margin-bottom: 16px;
}

.m-vb-3 {
  margin-bottom: 32px;
}


.m-hl-0 {
  margin-left: 0;
}

.m-hl-1 {
  margin-left: 8px;
}

.m-hl-2 {
  margin-left: 16px;
}


.m-hr-0 {
  margin-right: 0;
}

.m-hr-1 {
  margin-right: 8px;
}

.m-hr-2 {
  margin-right: 16px;
}


.m-h-auto {
  margin-left: auto;
  margin-right: auto;
}

// --

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.semi-bold {
  font-weight: 600;
}

.line-height-paragraph {
  line-height: 1.4em;
}

// --

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

// --

.width-100 {
  width: 100%;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}
