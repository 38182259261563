@charset "UTF-8";
@font-face {
  font-family: sofia-pro-bold;
  src: url("/assets/fonts/sofia-pro-bold/sofia-pro-bold.eot");
  src: url("/assets/fonts/sofia-pro-bold/sofia-pro-bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/sofia-pro-bold/sofia-pro-bold.woff2") format("woff2"), url("/assets/fonts/sofia-pro-bold/sofia-pro-bold.woff") format("woff"), url("/assets/fonts/sofia-pro-bold/sofia-pro-bold.ttf") format("truetype"), url("/assets/fonts/sofia-pro-bold/sofia-pro-bold.svg#sofia_probold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.text-bold {
  font-family: sofia-pro-bold, HelveticaNeue-Bold, Helvetica Neue Bold, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

@font-face {
  font-family: sofia-pro-light;
  src: url("/assets/fonts/sofia-pro-light/sofia-pro-light.eot");
  src: url("/assets/fonts/sofia-pro-light/sofia-pro-light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/sofia-pro-light/sofia-pro-light.woff2") format("woff2"), url("/assets/fonts/sofia-pro-light/sofia-pro-light.woff") format("woff"), url("/assets/fonts/sofia-pro-light/sofia-pro-light.ttf") format("truetype"), url("/assets/fonts/sofia-pro-light/sofia-pro-light.svg#sofia_prolight") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.text-light {
  font-family: sofia-pro-light, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

@font-face {
  font-family: sofia-pro-bold;
  src: url("/assets/fonts/sofia-pro-medium/sofia-pro-medium.eot");
  src: url("/assets/fonts/sofia-pro-medium/sofia-pro-medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/sofia-pro-medium/sofia-pro-medium.woff2") format("woff2"), url("/assets/fonts/sofia-pro-medium/sofia-pro-medium.woff") format("woff"), url("/assets/fonts/sofia-pro-medium/sofia-pro-medium.ttf") format("truetype"), url("/assets/fonts/sofia-pro-medium/sofia-pro-medium.svg#sofia_promedium") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.text-medium {
  font-family: sofia-pro-bold, HelveticaNeue-Medium, Helvetica Neue Medium, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

:root {
  --color-blue-alpha: #288fee5e;
  --border-radius-rounded: 50px;
  --box-shadow-0: 0 2px 4px -0.75px rgb(0 0 0 / 10%);
  --box-shadow-1: 0 2px 4px -0.75px rgb(0 0 0 / 25%);
}

body {
  padding: 0;
  margin: 0;
  font-family: "sofia-pro-light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  background-color: var(--color-black-98);
  font-size: 14px;
  color: var(--color-black-15);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "sofia-pro-light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

input {
  font-family: "sofia-pro-light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

textarea {
  resize: none;
  font-family: "sofia-pro-light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

a {
  color: var(--color-black-45);
}

select {
  background-color: white;
  border: 1px solid var(--color-black-90);
  border-radius: var(--border-radius-rounded);
  padding: 4px 6px;
  cursor: pointer;
  color: var(--color-black-45);
  font-family: "sofia-pro-light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.file-upload-input {
  position: relative;
}
.file-upload-input > input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.submenu, ng-autocomplete .autocomplete-container.active .suggestions-container {
  background: white;
  border-radius: 4px;
  border: 1px solid var(--color-black-90);
  color: var(--color-black-45);
}

.submenu-el, ng-autocomplete .autocomplete-container.active .suggestions-container .item a {
  padding: 8px;
  display: flex;
  justify-content: left;
  align-items: center;
  border-top: 1px solid var(--color-black-90);
  text-align: left;
  gap: 4px;
}
.submenu-el > svg, ng-autocomplete .autocomplete-container.active .suggestions-container .item a > svg {
  width: 18px;
}
.submenu-el.readonly, ng-autocomplete .autocomplete-container.active .suggestions-container .item a.readonly {
  cursor: default;
}
.submenu-el:not(.readonly):hover, ng-autocomplete .autocomplete-container.active .suggestions-container .item a:not(.readonly):hover {
  background-color: var(--color-black-98);
}
.submenu-el:not(.readonly):active, ng-autocomplete .autocomplete-container.active .suggestions-container .item a:not(.readonly):active {
  background-color: var(--color-black-95);
}
.submenu-el.active, ng-autocomplete .autocomplete-container.active .suggestions-container .item a.active {
  background-color: var(--color-black-95);
}
.submenu-el:first-child, ng-autocomplete .autocomplete-container.active .suggestions-container .item a:first-child {
  border-top: 0;
}

.submenu-divider + .submenu-el, ng-autocomplete .autocomplete-container.active .suggestions-container .item .submenu-divider + a {
  border-top-color: var(--color-black-75);
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}
.modal.general {
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 16px;
  position: absolute;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.modal-content pre {
  max-width: 800px;
  overflow: auto;
  background: var(--color-black-98);
  border-radius: 4px;
  padding: 16px;
}

.spinner-rotate {
  animation: spinner-rotate 2s infinite;
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.noselect, select, label {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.color-error {
  color: var(--color-error);
}

.text-size-10 {
  font-size: 10px;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix {
  *zoom: 1; /* Für den IE6 und IE7 */
}
.clearfix::before, .clearfix::after {
  content: " ";
  display: table;
}
.clearfix::after {
  clear: both;
}

.m-vt-0 {
  margin-top: 0;
}

.m-vt-1 {
  margin-top: 8px;
}

.m-vt-2 {
  margin-top: 16px;
}

.m-vt-3 {
  margin-top: 32px;
}

.m-vb-0 {
  margin-bottom: 0;
}

.m-vb-1 {
  margin-bottom: 8px;
}

.m-vb-2 {
  margin-bottom: 16px;
}

.m-vb-3 {
  margin-bottom: 32px;
}

.m-hl-0 {
  margin-left: 0;
}

.m-hl-1 {
  margin-left: 8px;
}

.m-hl-2 {
  margin-left: 16px;
}

.m-hr-0 {
  margin-right: 0;
}

.m-hr-1 {
  margin-right: 8px;
}

.m-hr-2 {
  margin-right: 16px;
}

.m-h-auto {
  margin-left: auto;
  margin-right: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.semi-bold {
  font-weight: 600;
}

.line-height-paragraph {
  line-height: 1.4em;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.width-100 {
  width: 100%;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

:root {
  --header-height: 40px;
}

.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 284px;
  background-color: var(--color-black-95);
  padding: 8px;
  overflow: auto;
  display: block;
}
.sidebar input,
.sidebar textarea {
  width: 100%;
}

@media only screen and (max-width: 599px) {
  .sidebar {
    width: initial;
    z-index: 999;
    left: 0;
  }
}
.ng-autocomplete {
  width: initial !important;
}

ng-autocomplete .autocomplete-container {
  box-shadow: none !important;
  height: initial !important;
}
ng-autocomplete .autocomplete-container .input-container .x {
  right: 3px;
}
ng-autocomplete .autocomplete-container .input-container .x > i {
  font-size: 18px;
}
ng-autocomplete .autocomplete-container.active .suggestions-container {
  box-shadow: none !important;
  margin-top: 1px;
  z-index: 1000;
  overflow: hidden;
}
ng-autocomplete .autocomplete-container.active .suggestions-container .item a {
  box-sizing: border-box;
  height: 34px;
  display: block;
}

:root {
  --color-active: #288fee;
  --color-error: #ff0033;
  --color-online: #66cc99;
  --color-black: black;
  --color-white: white;
  --color-white-alpha: rgba(255, 255, 255, 0.92);
  --color-0: #424242;
  --color-1: #9a6b58;
  --color-2: #f39c12;
  --color-3: #6cc04a;
  --color-4: #16a085;
  --color-5: #76c4d5;
  --color-6: #3498db;
  --color-7: #0075c9;
  --color-8: #9b59b6;
  --color-9: #e7417a;
  --color-10: #e63c2f;
  --color-black-15: #262626;
  --color-black-30: #4d4d4d;
  --color-black-45: #737373;
  --color-black-60: #999999;
  --color-black-75: #bfbfbf;
  --color-black-80: #cccccc;
  --color-black-90: #e6e6e6;
  --color-black-95: #f2f2f2;
  --color-black-98: #fafafa;
  --color-theme: #e63c2f;
  --color-theme-t-15: #ea594e;
  --color-theme-t-30: #ee776d;
  --color-theme-t-45: #f1948d;
  --color-theme-t-60: #f5b1ac;
  --color-theme-t-75: #f9cecb;
  --color-theme-t-90: #fdecea;
  --color-theme-s-15: #c43328;
  --color-theme-s-30: #a12a21;
  --color-theme-s-45: #7f211a;
  --color-theme-s-60: #5c1813;
  --color-theme-s-75: #3a0f0c;
  --color-theme-s-90: #170605;
  --border-radius: 4px;
  --border-radius-2: 10px;
  --shadow-1: 0 1px 7px 0 rgba(0,0,0,0.16);
  --shadow-2: 0 1px 3px 0 rgba(0,0,0,.2);
  --shadow-3: 0 1px 1px 0 rgba(0,0,0,.2);
  --shadow-4: 0 1px 2px 0 rgba(0,0,0,.08);
}

.noselect-base, .btn, button, .file-upload-input, .noselect, select, label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.noselect, select, label {
  cursor: default;
}

.b-transparent {
  border-color: transparent;
}

.display-flex {
  display: flex;
}

.btn, button, .file-upload-input {
  border-radius: var(--border-radius);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
  padding: 5px 6px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid transparent;
}
@media (hover: hover) {
  .btn, button, .file-upload-input {
    cursor: pointer;
  }
}
.rounded.btn, button.rounded, .rounded.file-upload-input {
  border-radius: 50px;
  padding: 5px !important;
  aspect-ratio: 1;
}
.rounded-text.btn, button.rounded-text, .rounded-text.file-upload-input {
  border-radius: 50px;
  padding: 5px 12px;
}
.multi-line.btn, button.multi-line, .multi-line.file-upload-input {
  height: auto !important;
  min-height: 30px;
}
.small.btn, button.btn, button, .file-upload-input {
  height: 26px;
}
.small.btn > svg, button.btn > svg, button > svg, .file-upload-input > svg {
  width: 14px;
}
.btn svg, button svg, .file-upload-input svg {
  background: none !important;
  width: 18px;
}

.btn-ow {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: transparent;
  color: white;
}
.btn-ow > svg {
  fill: white;
}
.btn-ow:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: white;
}
.btn-ow.active {
  background-color: white;
  color: var(--color-black-60) !important;
}
.btn-ow.active > svg {
  fill: var(--color-black-60);
}

.btn-b {
  background-color: var(--color-black-75);
  border-color: var(--color-black-75);
  color: var(--color-black-45);
}
.btn-b > svg {
  fill: var(--color-black-60);
}
.btn-b:hover {
  background-color: var(--color-black-60);
  border-color: var(--color-black-60);
  color: var(--color-black-30);
}
.btn-b:hover > svg {
  fill: var(--color-black-45);
}
.btn-b.active {
  color: white;
  background-color: var(--color-theme);
  border-color: var(--color-theme);
}
.btn-b.active > svg {
  fill: white !important;
}

.btn-b-2, button, .file-upload-input {
  background-color: white;
  border-color: var(--color-black-90);
  color: var(--color-black-45);
}
.btn-b-2 > svg, button > svg, .file-upload-input > svg {
  fill: var(--color-black-60);
}
.btn-b-2:hover, button:hover, .file-upload-input:hover {
  border-color: var(--color-black-60);
  color: var(--color-black-30);
}
.btn-b-2:hover > svg, button:hover > svg, .file-upload-input:hover > svg {
  fill: var(--color-black-45);
}
.btn-b-2.active, button.active, .active.file-upload-input {
  background-color: var(--color-black-30);
  border-color: var(--color-black-30);
  color: white;
}
.btn-b-2.active > svg, button.active > svg, .active.file-upload-input > svg {
  fill: white !important;
}

.btn-cl {
  background-color: var(--color-theme-t-45);
  border-color: var(--color-theme-t-45);
  color: var(--color-theme);
}
.btn-cl > svg {
  fill: var(--color-theme-t-15);
}
.btn-cl:hover {
  background-color: var(--color-theme-t-30);
  border-color: var(--color-theme-t-30);
  color: var(--color-theme-s-15);
}
.btn-cl:hover > svg {
  fill: var(--color-theme);
}
.btn-cl.active {
  background-color: var(--color-theme-t-90);
  border-color: var(--color-theme-t-90);
  color: var(--color-theme);
}
.btn-cl.active > svg {
  fill: var(--color-theme);
}

.btn-c {
  background-color: var(--color-theme);
  border-color: var(--color-theme);
  color: white;
}
.btn-c.active, .btn-c.active:hover {
  background-color: white;
  border-color: white;
  color: var(--color-theme);
}
.btn-c.active > svg, .btn-c.active:hover > svg {
  fill: var(--color-theme);
}
.btn-c > svg {
  fill: var(--color-theme-t-90);
}
.btn-c:hover {
  background-color: var(--color-theme-s-15);
  border-color: var(--color-theme-s-15);
  color: var(--color-theme-t-90);
}
.btn-c:hover > svg {
  fill: var(--color-theme-t-75);
}

.btn-t-b {
  background-color: transparent;
  color: var(--color-black-45);
}
.btn-t-b > svg {
  fill: var(--color-black-60);
}
.btn-t-b:hover {
  background-color: var(--color-black-75);
  border-color: var(--color-black-75);
  color: var(--color-black-30);
}
.btn-t-b:hover > svg {
  fill: var(--color-black-30);
}
.btn-t-b.active {
  color: white !important;
  background-color: var(--color-theme);
  border-color: var(--color-theme);
}
.btn-t-b.active > svg {
  fill: white !important;
}

.btn-t-c {
  background-color: transparent;
  color: white;
}
.btn-t-c.active, .btn-t-c.active:hover {
  background-color: white;
  border-color: white;
  color: var(--color-theme);
}
.btn-t-c.active > svg, .btn-t-c.active:hover > svg {
  fill: var(--color-theme);
}
.btn-t-c > svg {
  fill: var(--color-theme-t-90);
}
.btn-t-c:hover {
  background-color: var(--color-theme-s-15);
  border-color: var(--color-theme-s-15);
  color: var(--color-theme-t-90);
}
.btn-t-c:hover > svg {
  fill: var(--color-theme-t-75);
}

.btn-t-cl {
  background-color: transparent;
  color: var(--color-theme);
}
.btn-t-cl > svg {
  fill: var(--color-theme-t-15);
}
.btn-t-cl:hover {
  background-color: var(--color-theme-t-30);
  border-color: var(--color-theme-t-30);
  color: var(--color-theme-s-15);
}
.btn-t-cl:hover > svg {
  fill: var(--color-theme);
}
.btn-t-cl.active {
  background-color: var(--color-theme-t-90);
  border-color: var(--color-theme-t-90);
  color: var(--color-theme);
}
.btn-t-cl.active > svg {
  fill: var(--color-theme);
}

.btn-b-b {
  background-color: transparent;
  border-color: var(--color-black-75);
  color: var(--color-black-45);
}
.btn-b-b > svg {
  fill: var(--color-black-60);
}
.btn-b-b:hover {
  border-color: var(--color-black-60);
  color: var(--color-black-30);
}
.btn-b-b:hover > svg {
  fill: var(--color-black-45);
}
.btn-b-b.active {
  color: white !important;
  background-color: var(--color-theme);
  border-color: var(--color-theme);
}
.btn-b-b.active > svg {
  fill: white !important;
}

.btn-b-b-2 {
  border-color: var(--color-black-75);
  color: var(--color-black-45);
  background-color: transparent;
}
.btn-b-b-2 > svg {
  fill: var(--color-black-60);
}
@media (hover: hover) {
  .btn-b-b-2:hover {
    border-color: var(--color-black-60);
    color: var(--color-black-30);
  }
  .btn-b-b-2:hover > svg {
    fill: var(--color-black-45);
  }
}
.btn-b-b-2.active {
  background-color: var(--color-black-30);
  border-color: var(--color-black-30);
  color: white;
}
.btn-b-b-2.active > svg {
  fill: white !important;
}

.btn-b-cl {
  background-color: transparent;
  border-color: var(--color-theme-t-45);
  color: var(--color-theme);
}
.btn-b-cl > svg {
  fill: var(--color-theme-t-15);
}
.btn-b-cl:hover {
  border-color: var(--color-theme-t-30);
  color: var(--color-theme-s-15);
}
.btn-b-cl:hover > svg {
  fill: var(--color-theme);
}
.btn-b-cl.active {
  background-color: var(--color-theme-t-90);
  border-color: var(--color-theme-t-90);
  color: var(--color-theme);
}
.btn-b-cl.active > svg {
  fill: var(--color-theme);
}

.btn-b-c {
  background-color: transparent;
  border-color: white;
  color: white;
}
.btn-b-c.active, .btn-b-c.active:hover {
  background-color: white;
  border-color: white;
  color: var(--color-theme);
}
.btn-b-c.active > svg, .btn-b-c.active:hover > svg {
  fill: var(--color-theme);
}
.btn-b-c > svg {
  fill: var(--color-theme-t-90);
}
.btn-b-c:hover {
  border-color: var(--color-theme-s-15);
  color: var(--color-theme-t-90);
}
.btn-b-c:hover > svg {
  fill: var(--color-theme-t-75);
}

.input-blank, .textarea-blank, textarea, input, ng-autocomplete .autocomplete-container .input-container input, .input-cl, .textarea-cl, .input-c, .textarea-c, .input-b, .textarea-b {
  padding-left: 8px;
  padding-right: 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--border-radius);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 26px;
  line-height: 26px;
  outline: none;
  box-sizing: border-box;
}
.input-blank:-webkit-autofill, .textarea-blank:-webkit-autofill, textarea:-webkit-autofill, input:-webkit-autofill, ng-autocomplete .autocomplete-container .input-container input:-webkit-autofill, .input-cl:-webkit-autofill, .textarea-cl:-webkit-autofill, .input-c:-webkit-autofill, .textarea-c:-webkit-autofill, .input-b:-webkit-autofill, .textarea-b:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px lightgrey inset;
  -webkit-text-fill-color: grey;
}

.input-b, .textarea-b {
  border-color: var(--color-black-95);
  background-color: var(--color-black-95);
  color: var(--color-black-45);
}
.input-b.error, .error.textarea-b {
  background-color: var(--color-black-90);
  border-color: var(--color-error) !important;
}

.input-c, .textarea-c {
  border-color: var(--color-theme-s-30);
  background-color: var(--color-theme-s-30);
  color: var(--color-theme-t-45);
}
.input-c::-webkit-input-placeholder, .textarea-c::-webkit-input-placeholder {
  color: var(--color-theme-t-15);
}
.input-c::-moz-placeholder, .textarea-c::-moz-placeholder {
  color: var(--color-theme-t-15);
}
.input-c:-moz-placeholder, .textarea-c:-moz-placeholder {
  color: var(--color-theme-t-15);
}
.input-c:-ms-input-placeholder, .textarea-c:-ms-input-placeholder {
  color: var(--color-theme-t-15);
}
.input-c.error, .error.textarea-c {
  background-color: var(--color-theme-s-30) !important;
  border-color: white !important;
}

.input-cl, .textarea-cl {
  border-color: var(--color-theme-t-60);
  background-color: var(--color-theme-t-60);
  color: var(--color-theme-s-15);
}
.input-cl::-webkit-input-placeholder, .textarea-cl::-webkit-input-placeholder {
  color: var(--color-theme);
}
.input-cl::-moz-placeholder, .textarea-cl::-moz-placeholder {
  color: var(--color-theme);
}
.input-cl:-moz-placeholder, .textarea-cl:-moz-placeholder {
  color: var(--color-theme);
}
.input-cl:-ms-input-placeholder, .textarea-cl:-ms-input-placeholder {
  color: var(--color-theme);
}
.input-cl.error, .error.textarea-cl {
  background-color: var(--color-theme-t-45) !important;
  border-color: var(--color-error) !important;
}

.input-blank, .textarea-blank, textarea, input, ng-autocomplete .autocomplete-container .input-container input {
  border-color: white;
  outline-color: white;
  color: var(--color-black-45);
}
.input-blank.error, .error.textarea-blank, textarea.error, input.error, ng-autocomplete .autocomplete-container .input-container input.error {
  border-color: var(--color-error) !important;
}

.input-b, .input-c, .input-cl, .input-blank, input, ng-autocomplete .autocomplete-container .input-container input, .textarea-b, .textarea-c, .textarea-cl, .textarea-blank, textarea {
  padding-left: 8px;
  padding-right: 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--border-radius);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 26px;
  line-height: 26px;
  outline: none;
  box-sizing: border-box;
}

input:-webkit-autofill, ng-autocomplete .autocomplete-container .input-container input:-webkit-autofill, .textarea-b:-webkit-autofill, .textarea-c:-webkit-autofill, .textarea-cl:-webkit-autofill, .textarea-blank:-webkit-autofill, textarea:-webkit-autofill, .input-blank:-webkit-autofill, .input-cl:-webkit-autofill, .input-c:-webkit-autofill, .input-b:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px lightgrey inset;
  -webkit-text-fill-color: grey;
}

.input-b, .textarea-b {
  border-color: var(--color-black-95);
  background-color: var(--color-black-95);
  color: var(--color-black-45);
}

.input-b.error, .error.textarea-b {
  background-color: var(--color-black-90);
  border-color: var(--color-error) !important;
}

.input-c, .textarea-c {
  border-color: var(--color-theme-s-30);
  background-color: var(--color-theme-s-30);
  color: var(--color-theme-t-45);
}

.input-c::-webkit-input-placeholder, .textarea-c::-webkit-input-placeholder {
  color: var(--color-theme-t-15);
}

.input-c::-moz-placeholder, .textarea-c::-moz-placeholder {
  color: var(--color-theme-t-15);
}

.input-c:-moz-placeholder, .textarea-c:-moz-placeholder {
  color: var(--color-theme-t-15);
}

.input-c:-ms-input-placeholder, .textarea-c:-ms-input-placeholder {
  color: var(--color-theme-t-15);
}

.input-c.error, .error.textarea-c {
  background-color: var(--color-theme-s-30) !important;
  border-color: white !important;
}

.input-cl, .textarea-cl {
  border-color: var(--color-theme-t-60);
  background-color: var(--color-theme-t-60);
  color: var(--color-theme-s-15);
}

.input-cl::-webkit-input-placeholder, .textarea-cl::-webkit-input-placeholder {
  color: var(--color-theme);
}

.input-cl::-moz-placeholder, .textarea-cl::-moz-placeholder {
  color: var(--color-theme);
}

.input-cl:-moz-placeholder, .textarea-cl:-moz-placeholder {
  color: var(--color-theme);
}

.input-cl:-ms-input-placeholder, .textarea-cl:-ms-input-placeholder {
  color: var(--color-theme);
}

.input-cl.error, .error.textarea-cl {
  background-color: var(--color-theme-t-45) !important;
  border-color: var(--color-error) !important;
}

.input-blank, input, ng-autocomplete .autocomplete-container .input-container input, .textarea-blank, textarea {
  border-color: white;
  outline-color: white;
  color: var(--color-black-45);
}

.input-blank.error, input.error, ng-autocomplete .autocomplete-container .input-container input.error, .error.textarea-blank, textarea.error {
  border-color: var(--color-error) !important;
}

.textarea-blank, textarea, .textarea-cl, .textarea-c, .textarea-b {
  line-height: 21px;
}

.e-submenu {
  padding: 0 !important;
  background-color: white;
  border: 1px solid var(--color-black-90);
  box-shadow: var(--shadow-4);
  border-radius: var(--border-radius);
}

.e-submenu-row {
  padding: 9px 16px;
  width: 100%;
  line-height: 19px;
  text-align: left;
  position: relative;
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
}
.e-submenu-row svg {
  width: 15px;
  font-size: 15px;
  fill: var(--color-black-30);
  background: none !important;
}
.e-submenu-row.icon-padding {
  padding-left: 44px;
}
.e-submenu-row.readonly {
  cursor: default !important;
}
.e-submenu-row.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.e-submenu-row-label {
  flex: 1;
}

.e-submenu-section-custom > a, .e-submenu-section-grey > a, .e-submenu-section-white > a {
  display: block;
}

.e-submenu-section-custom:first-child, .e-submenu-section-grey:first-child, .e-submenu-section-white:first-child {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.e-submenu-section-custom:last-child, .e-submenu-section-grey:last-child, .e-submenu-section-white:last-child {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.e-submenu-section-custom:last-child > a:last-child .e-submenu-row, .e-submenu-section-grey:last-child > a:last-child .e-submenu-row, .e-submenu-section-white:last-child > a:last-child .e-submenu-row {
  border-bottom: 0 !important;
}

.e-submenu-section-white .e-submenu-row {
  border-color: var(--color-black-90);
  color: var(--color-black-30);
}
.e-submenu-section-white .e-submenu-row.active {
  background-color: var(--color-black-75);
  color: white;
}
.e-submenu-section-white .e-submenu-row.active svg {
  fill: white;
}
.e-submenu-section-white:not(.no-hover) .e-submenu-row:not(.readonly):hover {
  background-color: var(--color-black-90);
}
.e-submenu-section-white:not(.no-hover) .e-submenu-row:not(.readonly).active:hover {
  background-color: var(--color-black-75);
  color: white;
}
.e-submenu-section-white:not(.no-hover) .e-submenu-row:not(.readonly).active:hover svg {
  fill: white;
}

.e-submenu-section-custom > a, .e-submenu-section-white > a, .e-submenu-section-grey > a {
  display: block;
}

.e-submenu-section-custom:first-child, .e-submenu-section-white:first-child, .e-submenu-section-grey:first-child {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.e-submenu-section-custom:last-child, .e-submenu-section-white:last-child, .e-submenu-section-grey:last-child {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.e-submenu-section-custom:last-child > a:last-child .e-submenu-row, .e-submenu-section-white:last-child > a:last-child .e-submenu-row, .e-submenu-section-grey:last-child > a:last-child .e-submenu-row {
  border-bottom: 0 !important;
}

.e-submenu-section-grey {
  background-color: var(--color-black-95);
}
.e-submenu-section-grey .e-submenu-row {
  border-color: transparent;
  color: var(--color-black-30);
}
.e-submenu-section-grey .e-submenu-row.active {
  background-color: var(--color-black-80);
  color: white;
}
.e-submenu-section-grey .e-submenu-row.active svg {
  fill: white;
}
.e-submenu-section-grey:not(.no-hover) .e-submenu-row:not(.readonly):hover {
  background-color: var(--color-black-90);
}
.e-submenu-section-grey:not(.no-hover) .e-submenu-row:not(.readonly).active:hover {
  background-color: var(--color-black-80);
  color: white;
}
.e-submenu-section-grey:not(.no-hover) .e-submenu-row:not(.readonly).active:hover svg {
  fill: white;
}

.e-submenu-section-white > a, .e-submenu-section-grey > a, .e-submenu-section-custom > a {
  display: block;
}

.e-submenu-section-white:first-child, .e-submenu-section-grey:first-child, .e-submenu-section-custom:first-child {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.e-submenu-section-white:last-child, .e-submenu-section-grey:last-child, .e-submenu-section-custom:last-child {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.e-submenu-section-white:last-child > a:last-child .e-submenu-row, .e-submenu-section-grey:last-child > a:last-child .e-submenu-row, .e-submenu-section-custom:last-child > a:last-child .e-submenu-row {
  border-bottom: 0 !important;
}

.e-submenu-section-custom {
  background-color: var(--color-theme-s-15);
}
.e-submenu-section-custom .e-submenu-row {
  color: var(--color-theme-t-60);
  border-color: var(--color-theme-t-45);
}
.e-submenu-section-custom .e-submenu-row svg {
  fill: var(--color-theme-t-45);
}
.e-submenu-section-custom .e-submenu-row.active {
  color: white;
}
.e-submenu-section-custom .e-submenu-row.active svg {
  fill: white;
}