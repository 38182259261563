$sophia-light-path: '/assets/fonts/sofia-pro-light';
$sophia-medium-path: '/assets/fonts/sofia-pro-medium';
$sophia-bold-path: '/assets/fonts/sofia-pro-bold';

@import "../../ui/scss/fonts/fonts";

$font-family: $font-family-light;


:root {
  --color-blue-alpha: #288fee5e;
  --border-radius-rounded: 50px;

    // TODO: replace these box-shadows with them from ui project
  --box-shadow-0: 0 2px 4px -0.75px rgb(0 0 0 / 10%);
  --box-shadow-1: 0 2px 4px -0.75px rgb(0 0 0 / 25%);
}

body {
  padding: 0;
  margin: 0;
  font-family: $font-family;
  background-color: var(--color-black-98);
  font-size: 14px;
  color: var(--color-black-15);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// --

button {
  @extend .btn;
  @extend .btn-b-2;
  //@extend .rounded-text; // TODO:
  @extend .small;
  font-family: $font-family;
}

input {
  @extend .input-blank;
  font-family: $font-family;
}

textarea {
    @extend .textarea-blank;
    resize: none;
    font-family: $font-family;
}

a {
  color: var(--color-black-45)
}

select {
  background-color: white;
  border: 1px solid var(--color-black-90);
  border-radius: var(--border-radius-rounded);
  padding: 4px 6px;
  cursor: pointer;
  color: var(--color-black-45);
  font-family: $font-family;
  @extend .noselect;
}

.file-upload-input {
  @extend .btn;
  @extend .btn-b-2;
  //@extend .rounded-text; // TODO:
  @extend .small;
  position: relative;

  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  //> svg {
  //  width: 12px;
  //  margin-right: 4px;
  //}
  //
  //&:hover {
  //  background-color: var(--color-black-98);
  //  border-color: var(--color-black-45);
  //}
  //
  //&:active {
  //  background-color: var(--color-black-95);
  //  border-color: var(--color-black-45);
  //}
}

.submenu {
  background: white;
  border-radius: 4px;
  border: 1px solid var(--color-black-90);
  color: var(--color-black-45);
}

.submenu-el {
  padding: 8px;
  display: flex;
  justify-content: left;
  align-items: center;
  border-top: 1px solid var(--color-black-90);
  text-align: left;
  gap: 4px;

  > svg {
    width: 18px;
  }

  &.readonly {
    cursor: default;
  }

  &:not(.readonly) {
    &:hover {
      background-color: var(--color-black-98);
    }

    &:active {
      background-color: var(--color-black-95);
    }
  }

  &.active {
    background-color: var(--color-black-95);
  }

  &:first-child {
    border-top: 0;
  }
}

.submenu-divider {
  + .submenu-el {
    border-top-color: var(--color-black-75);
  }
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;

  &.general {
    background: rgba(0,0,0,.5); // TODO: create css variable
  }
}

.modal-content {
  background-color: white;
  padding: 16px;
  position: absolute;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  pre {
    max-width: 800px;
    overflow: auto;
    background: var(--color-black-98);
    border-radius: 4px;
    padding: 16px;
  }
}


.spinner-rotate {
    animation: spinner-rotate 2s infinite;
}

@keyframes spinner-rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
