
.btn-t-b {
  background-color: transparent;
  color: var(--color-black-45);

  > svg {
    fill: var(--color-black-60);
  }

  &:hover {
    background-color: var(--color-black-75);
    border-color: var(--color-black-75);
    color: var(--color-black-30);

    > svg {
      fill: var(--color-black-30);
    }
  }

  &.active {
    color: white !important;
    background-color: var(--color-theme);
    border-color: var(--color-theme);

    > svg {
      fill: white !important;
    }
  }
}
