
.e-submenu-row {
    //padding: 9px 16px 9px 35px;
    padding: 9px 16px;
    width: 100%;
    line-height: 19px;
    text-align: left;
    position: relative;
    cursor: pointer;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: flex;
    align-items: center;
    gap: 12px;
    box-sizing: border-box;

    //&.loading {
    //    .e-submenu-row-icon {
    //        visibility: hidden;
    //    }
    //}

    svg {
        width: 15px;
        font-size: 15px;
        fill: var(--color-black-30);
        background: none !important;
    }

    &.icon-padding {
        padding-left: 44px;
    }

    &.readonly {
        cursor: default !important;
        //height: auto !important;
        //word-wrap: break-word;
    }

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }

    //&.hide-icon {
    //    .e-submenu-row-icon {
    //        visibility: hidden;
    //        pointer-events: none;
    //    }
    //}
}

.e-submenu-row-label {
    flex: 1;
}


//svg {
//    &.e-submenu-row-icon {
//        background: none !important;
//    }
//}

//.e-submenu-row-spinner {
//  width: 11px;
//  height: 11px;
//  position: absolute;
//  left: 7px;
//  top: 6px;
//}

//.e-submenu-row-count {
//  position: absolute;
//  right: -20px;
//  top: 4px;
//  width: 20px;
//  text-align: center;
//}
