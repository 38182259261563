
.btn-c {
  background-color: var(--color-theme);
  border-color: var(--color-theme);
  color: white;

  &.active,
  &.active:hover {
    background-color: white;
    border-color: white;
    color: var(--color-theme);

    > svg {
      fill: var(--color-theme);
    }
  }

  > svg {
    fill: var(--color-theme-t-90);
  }

  &:hover {
    background-color: var(--color-theme-s-15);
    border-color: var(--color-theme-s-15);
    color: var(--color-theme-t-90);

    > svg {
      fill: var(--color-theme-t-75);
    }
  }

  //&.active:active,
  //&:active {
  //  background-color: var(--color-theme-s-30) !important;
  //  border-color: var(--color-theme-s-30) !important;
  //  color: var(--color-theme-t-75) !important;
  //}
}
