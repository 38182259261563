
:root {
    --header-height: 40px;
}


.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 284px; // +16px padding = 300px
  background-color: var(--color-black-95);
  padding: 8px;
  overflow: auto;
  display: block;

  input,
  textarea {
      width: 100%;
    //width: calc(100% - 22px);
  }
}


@media only screen and (max-width: 599px) {
  .sidebar {
    width: initial;
    z-index: 999;
    left: 0;
  }
}
