@import "../../utilities/base";

%btn-base {
    border-radius: var(--border-radius);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 6px;
    padding: 5px 6px;
    height: 30px;
    //font-size: 12px;
    box-sizing: border-box;
    border: 1px solid transparent;
    @extend .noselect-base;

    @media (hover: hover) {
        cursor: pointer;
    }

    &.rounded {
        border-radius: 50px;
        padding: 5px !important;
        aspect-ratio: 1;
    }

    &.rounded-text {
        border-radius: 50px;
        padding: 5px 12px; // TODO: maybe padding: 6px horizontally is enough ?! -> only one padding property
    }

    &.multi-line {
        height: auto !important;
        min-height: 30px;
    }

    //&.centered-icon {
    //    svg {
    //        float: none;
    //    }
    //}

    //&.btn-width-auto {
    //    width: auto !important;
    //}

    &.small {
        height: 26px;
        //padding: 5px !important;
        //line-height: 15px;

        //&:not(.btn-width) {
        //    width: 26px;
        //}

        > svg {
            width: 14px;
        }
    }

    svg {
        background: none !important;
        width: 18px;
    }

    // TODO: &.active, &:active
}


.btn {
    @extend %btn-base;
}

//.popup-btn {
//    @extend .btn;
//    height: 37px;
//    font-size: 14px;
//    padding: 5px 12px;
//    line-height: 25px;
//
//    > svg {
//        width: 26px;
//    }
//}
//
//.btn-icon-1 {
//    float: left;
//}

//.btn-text {
//    padding: 0 5px;
//    display: inline-block;
//    text-align: inherit;
//}

//.btn-icon-2 {
//    float: right;
//}
//
//.btn-width {
//    width: $btn-width;
//}
//
//.btn-min-width {
//    min-width: $btn-width;
//}


@import "general/opacity-white";
@import "general/black";
@import "general/black-2";
@import "general/custom-light";
@import "general/custom";

@import "transparent/black";
@import "transparent/custom";
@import "transparent/custom-light";

@import "bordered/black";
@import "bordered/black-2";
@import "bordered/custom-light";
@import "bordered/custom";
