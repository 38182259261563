$sophia-light-path: "/fonts" !default;
$font-light: "sofia-pro-light";
$font-family-light: $font-light, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

@font-face {
  font-family: #{$font-light};
  src: url("#{$sophia-light-path}/sofia-pro-light.eot");
  src: url("#{$sophia-light-path}/sofia-pro-light.eot?#iefix") format("embedded-opentype"),
  url("#{$sophia-light-path}/sofia-pro-light.woff2") format("woff2"),
  url("#{$sophia-light-path}/sofia-pro-light.woff") format("woff"),
  url("#{$sophia-light-path}/sofia-pro-light.ttf") format("truetype"),
  url("#{$sophia-light-path}/sofia-pro-light.svg#sofia_prolight") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.text-light {
    font-family: #{$font-family-light};
}

