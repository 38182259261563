
%e-submenu-section {

    > a {
        display: block;
    }

    //&.no-icons {
    //    .e-submenu-row {
    //        padding-left: 16px !important;
    //    }
    //}

    &:first-child {
        border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    &:last-child {
        border-radius: 0 0 var(--border-radius) var(--border-radius);

        > a {
            &:last-child {
                .e-submenu-row {
                    border-bottom: 0 !important;
                }
            }
        }
    }
}
