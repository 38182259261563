@use "./input/input" as *;


%textarea-base {
    line-height: 21px;
}

.textarea-b {
    @extend %textarea-base;
    @extend .input-b;
}

.textarea-c {
    @extend %textarea-base;
    @extend .input-c;
}

.textarea-cl {
    @extend %textarea-base;
    @extend .input-cl;
}


.textarea-blank {
    @extend %textarea-base;
    @extend .input-blank;
}
