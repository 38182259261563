
.input-blank {
    @extend %input-base;
    border-color: white;
    outline-color: white;
    color: var(--color-black-45);

    &.error {
        border-color: var(--color-error) !important;
        //box-shadow: 0 0 0 1px var(--color-black-75) !important;
    }
}
