@use "../../../mixins/placeholder" as *;

.input-cl {
    @extend %input-base;
    border-color: var(--color-theme-t-60);
    background-color: var(--color-theme-t-60);
    color: var(--color-theme-s-15);

    @include placeholder {
      color: var(--color-theme);
    }

    &.error {
        background-color: var(--color-theme-t-45) !important;
        border-color: var(--color-error) !important;
        //box-shadow: 0 0 0 1px $color !important;
    }
}
