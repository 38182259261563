@use "../../mixins/appearance" as *;

%input-base {
    padding-left: 8px;
    padding-right: 8px;
    //font-size: 14px !important;
    border-width: 1px;
    border-style: solid;
    //outline-offset: -2px;
    border-radius: var(--border-radius);
    @include appearance(none);
    //@extend %font-medium;
    height: 26px;
    line-height: 26px;
    outline: none;
    box-sizing: border-box;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 1000px lightgrey inset;
        -webkit-text-fill-color: grey;
    }

    // TODO: &:hover
    // TODO: &:focus-visible
    // TODO: &[disabled]
}

//.e-input-width-auto-wrapper {
//    display: inline-grid;
//
//    input {
//        grid-area: 1/2;
//        width: auto;
//    }
//
//    &:after {
//        content: attr(data-value) " ";
//        visibility: hidden;
//        white-space: pre-wrap;
//        grid-area: 1/2;
//        width: auto;
//        font-size: 14px; // must have the same size as the input element
//    }
//}


@import "./styles/black";
@import "./styles/custom";
@import "./styles/custom-light";
@import "./styles/blank";
