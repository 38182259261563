@use "../../../mixins/placeholder" as *;

.input-c {
    @extend %input-base;
    border-color: var(--color-theme-s-30);
    background-color: var(--color-theme-s-30);
    color: var(--color-theme-t-45);

    @include placeholder {
      color: var(--color-theme-t-15);
    }

    &.error {
        background-color: var(--color-theme-s-30) !important;
        border-color: white !important;
        //box-shadow: 0 0 0 1px white !important;
    }
}
