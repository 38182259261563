
.btn-b {
  background-color: var(--color-black-75);
  border-color: var(--color-black-75);
  color: var(--color-black-45);

  > svg {
    fill: var(--color-black-60);
  }

  &:hover {
    background-color: var(--color-black-60);
    border-color: var(--color-black-60);
    color: var(--color-black-30);

    > svg {
      fill: var(--color-black-45);
    }
  }

  &.active {
    color: white;
    background-color: var(--color-theme);
    border-color: var(--color-theme);

    > svg {
      fill: white !important;
    }
  }
}
