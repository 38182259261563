
.btn-b-cl {
  background-color: transparent;
  border-color: var(--color-theme-t-45);
  color: var(--color-theme);

  > svg {
    fill: var(--color-theme-t-15);
  }

  &:hover {
    border-color: var(--color-theme-t-30);
    color: var(--color-theme-s-15);

    > svg {
      fill: var(--color-theme)
    }
  }

  //&.active:active,
  //&:active {
  //  background-color: var(--color-theme-t-15) !important;
  //  border-color: var(--color-theme-t-15) !important;
  //  color: var(--color-theme-s-30) !important;
  //}

  &.active {
    background-color: var(--color-theme-t-90);
    border-color: var(--color-theme-t-90);
    color: var(--color-theme);

    > svg {
      fill: var(--color-theme)
    }
  }
}
