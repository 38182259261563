$sophia-bold-path: "/fonts" !default;
$font-bold: "sofia-pro-bold";
$font-family-bold: $font-bold, "HelveticaNeue-Bold", "Helvetica Neue Bold", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

@font-face {
  font-family: #{$font-bold};
  src: url("#{$sophia-bold-path}/sofia-pro-bold.eot");
  src: url("#{$sophia-bold-path}/sofia-pro-bold.eot?#iefix") format("embedded-opentype"),
  url("#{$sophia-bold-path}/sofia-pro-bold.woff2") format("woff2"),
  url("#{$sophia-bold-path}/sofia-pro-bold.woff") format("woff"),
  url("#{$sophia-bold-path}/sofia-pro-bold.ttf") format("truetype"),
  url("#{$sophia-bold-path}/sofia-pro-bold.svg#sofia_probold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.text-bold {
    font-family: #{$font-family-bold};
}
