
.btn-ow {
    background-color: rgba(white, .4);
    border-color: transparent;
    color: white;

    > svg {
        fill: white;
    }

    &:hover {
        background-color: rgba(white, .5);
        color: white;
    }

    //&.active:active,
    //&:active {
    //  background-color: rgba(white, .6) !important;
    //  color: white;
    //}

    &.active {
        background-color: white;
        color: var(--color-black-60) !important;

        > svg {
            fill: var(--color-black-60);
        }
    }
}
