
.e-submenu {
    padding: 0 !important;
    //overflow: hidden;
    background-color: white;
    border: 1px solid var(--color-black-90);
    box-shadow: var(--shadow-4);
    border-radius: var(--border-radius);
}


@import "./row";
@import "./sections/white";
@import "./sections/grey";
@import "./sections/custom";
