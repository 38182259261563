.ng-autocomplete {
  width: initial !important;
}

ng-autocomplete .autocomplete-container {
  box-shadow: none !important;
  height: initial !important;

  .input-container {

    input {
        @extend .input-blank;
      //background-color: white !important;
      //border: 1px solid var(--color-black-90) !important;
      //border-radius: var(--border-radius) !important;
      //padding: 4px 10px !important;
      //color: var(--color-black-45) !important;
      //font-family: $font-family !important;
      //height: initial !important;
      //line-height: initial !important;
      //
      //&:hover {
      //  border-color: var(--color-black-45) !important;
      //}
      //
      //&:focus-visible {
      //  border-color: var(--color-theme) !important;
      //  outline: 1px solid var(--color-theme) !important;
      //}
    }

    .x {
      right: 3px;

      > i {
        font-size: 18px;
      }
    }
  }

  &.active {
    .suggestions-container {
      @extend .submenu;
      box-shadow: none !important;
      margin-top: 1px;
      z-index: 1000;
      overflow: hidden;

      .item a {
        @extend .submenu-el;
        box-sizing: border-box;
        height: 34px;
        display: block;
      }
    }
  }
}
