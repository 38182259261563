
.btn-b-b {
  background-color: transparent;
  border-color: var(--color-black-75);
  color: var(--color-black-45);

  > svg {
    fill: var(--color-black-60);
  }

  &:hover {
    border-color: var(--color-black-60);
    color: var(--color-black-30);

    > svg {
      fill: var(--color-black-45);
    }
  }

  //&.active:active,
  //&:active {
  //  background-color: var(--color-black-45) !important;
  //  border-color: var(--color-black-45) !important;
  //  color: var(--color-black-15) !important;
  //}

  &.active {
    color: white !important;
    background-color: var(--color-theme);
    border-color: var(--color-theme);

    > svg {
      fill: white !important;
    }
  }
}
