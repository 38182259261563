
.input-b {
    @extend %input-base;
    border-color: var(--color-black-95);
    background-color: var(--color-black-95);
    color: var(--color-black-45);

    &.error {
        background-color: var(--color-black-90);
        border-color: var(--color-error) !important;
        //box-shadow: 0 0 0 1px var(--color-black-45) !important;
    }
}
