@use "./base" as *;

.e-submenu-section-grey {
    @extend %e-submenu-section;
    background-color: var(--color-black-95);

    .e-submenu-row {
        border-color: transparent;
        color: var(--color-black-30);

        &.active {
            background-color: var(--color-black-80);
            color: white;

            svg {
                fill: white;
            }
        }
    }

    &:not(.no-hover) {

        .e-submenu-row {

            &:not(.readonly) {

                &:hover {
                    background-color: var(--color-black-90);
                }

                &.active:hover {
                    background-color: var(--color-black-80);
                    color: white;

                    svg {
                        fill: white;
                    }
                }
            }
        }
    }
}
