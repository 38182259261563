
.noselect-base {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.noselect {
  @extend .noselect-base;
  cursor: default;
}

.b-transparent {
    border-color: transparent;
}

.display-flex {
    display: flex;
}
