
.btn-b-2 {
    background-color: white;
    border-color: var(--color-black-90);
    color: var(--color-black-45);

    > svg {
        fill: var(--color-black-60);
    }

    &:hover {
        border-color: var(--color-black-60);
        color: var(--color-black-30);

        > svg {
            fill: var(--color-black-45);
        }
    }

    &.active {
        background-color: var(--color-black-30);
        border-color: var(--color-black-30);
        color: white;

        > svg {
            fill: white !important;
        }
    }
}
