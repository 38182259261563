
.btn-b-b-2 {
    border-color: var(--color-black-75);
    color: var(--color-black-45);
    background-color: transparent;

    > svg {
        fill: var(--color-black-60);
    }

    @media (hover: hover) {

        &:hover {
            border-color: var(--color-black-60);
            color: var(--color-black-30);

            > svg {
                fill: var(--color-black-45);
            }
        }
    }

    &.active {
        background-color: var(--color-black-30);
        border-color: var(--color-black-30);
        color: white;

        > svg {
            fill: white !important;
        }
    }
}
