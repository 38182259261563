$sophia-medium-path: "/fonts" !default;
$font-medium: "sofia-pro-bold";
$font-family-medium: $font-medium, "HelveticaNeue-Medium", "Helvetica Neue Medium", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

@font-face {
  font-family: #{$font-medium};
  src: url("#{$sophia-medium-path}/sofia-pro-medium.eot");
  src: url("#{$sophia-medium-path}/sofia-pro-medium.eot?#iefix") format("embedded-opentype"),
  url("#{$sophia-medium-path}/sofia-pro-medium.woff2") format("woff2"),
  url("#{$sophia-medium-path}/sofia-pro-medium.woff") format("woff"),
  url("#{$sophia-medium-path}/sofia-pro-medium.ttf") format("truetype"),
  url("#{$sophia-medium-path}/sofia-pro-medium.svg#sofia_promedium") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.text-medium {
    font-family: #{$font-family-medium};
}
