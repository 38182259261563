$color-white: white;
$color-black: black;

:root {
    --color-active: #288fee;
    --color-error: #ff0033;
    --color-online: #66cc99;
    --color-black: #{$color-black};
    --color-white: #{$color-white};
    --color-white-alpha: #{rgba($color-white, .92)};

    --color-0: #424242;
    --color-1: #9a6b58;
    --color-2: #f39c12;
    --color-3: #6cc04a;
    --color-4: #16a085;
    --color-5: #76c4d5;
    --color-6: #3498db;
    --color-7: #0075c9;
    --color-8: #9b59b6;
    --color-9: #e7417a;
    --color-10: #e63c2f;

    --color-black-15: #262626;
    --color-black-30: #4d4d4d;
    --color-black-45: #737373;
    --color-black-60: #999999;
    --color-black-75: #bfbfbf;
    --color-black-80: #cccccc;
    --color-black-90: #e6e6e6;
    --color-black-95: #f2f2f2;
    --color-black-98: #fafafa;

    // default theme colors
    --color-theme: #e63c2f;
    --color-theme-t-15: #ea594e;
    --color-theme-t-30: #ee776d;
    --color-theme-t-45: #f1948d;
    --color-theme-t-60: #f5b1ac;
    --color-theme-t-75: #f9cecb;
    --color-theme-t-90: #fdecea;
    --color-theme-s-15: #c43328;
    --color-theme-s-30: #a12a21;
    --color-theme-s-45: #7f211a;
    --color-theme-s-60: #5c1813;
    --color-theme-s-75: #3a0f0c;
    --color-theme-s-90: #170605;

    --border-radius: 4px;
    --border-radius-2: 10px;

    --shadow-1: 0 1px 7px 0 rgba(0,0,0,0.16);
    --shadow-2: 0 1px 3px 0 rgba(0,0,0,.2);
    --shadow-3: 0 1px 1px 0 rgba(0,0,0,.2);
    --shadow-4: 0 1px 2px 0 rgba(0,0,0,.08);
}
