@use "./base" as *;

.e-submenu-section-custom {
    @extend %e-submenu-section;
    background-color: var(--color-theme-s-15);

    .e-submenu-row {
        color: var(--color-theme-t-60);
        border-color: var(--color-theme-t-45);

        svg {
            fill: var(--color-theme-t-45)
        }

        &.active {
            color: white;
            //text-decoration: underline;

            svg {
                fill: white;
            }
        }
    }
}
